import { Box, Button } from '@material-ui/core';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import axios from 'axios';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import 'pdfjs-dist/web/pdf_viewer.css';
import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import { apiHnsn, apiLaureano, apiLaureanoSoul } from '../../services/api';
import './style.css';
(
  pdfjsLib as any
).GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${(pdfjsLib as any).version
}/pdf.worker.min.js`;
function LaudoView() {
  const { cdLaudo } = useParams() as any;
  const from = localStorage.getItem('@cedapp:from');
  const { tokenHnsn, tokenLaureano, tokenLaureanoSoul } = useAuth();
  const viewerRef = useRef<HTMLDivElement>(null);

  function getTokenLaudo(): string {
    if (from === 'LAUREANO') {


      return `${apiLaureanoSoul.defaults.baseURL}/laudo/android/${tokenLaureanoSoul}/${cdLaudo}`;
    } else if (from === 'LAUREANO_MV2000') {


      return `${apiLaureano.defaults.baseURL}/laudo/android/${tokenLaureano}/${cdLaudo}`;
    } else {

      return `${apiHnsn.defaults.baseURL}/laudo/android/${tokenHnsn}/${cdLaudo}`;
    }
  }

  const isiOS = (): boolean => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  };

  const download = (): void => {
    axios
      .get(getTokenLaudo(), {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/pdf',
        },
      })
      .then(async (response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        await (window.navigator as any).share({
          files: [new File([blob], 'file.pdf', { type: 'application/pdf' })],
        });
        // const url = window.URL.createObjectURL(new Blob([response.data]));
        // const link = document.createElement('a');
        // link.href = url;
        // link.setAttribute('download', 'file.pdf'); //or any other extension
        // document.body.appendChild(link);
        // link.click();
      })
      .catch((error) => console.log(error));
  };
  const isAndroid = (): boolean => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /android/.test(userAgent);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      const loadingTask = pdfjsLib.getDocument(getTokenLaudo());

      loadingTask.promise.then((pdf: any) => {
        const numPages = pdf.numPages;
        const viewer = viewerRef.current;

        if (viewer) {
          viewer.innerHTML = '';

          for (let pageNum = 1; pageNum <= numPages; pageNum++) {
            pdf.getPage(pageNum).then((page: any) => {
              const viewport = page.getViewport({ scale: 1.5 });
              const canvas = document.createElement('canvas');
              const context = canvas.getContext('2d');

              canvas.height = viewport.height;
              canvas.width = viewport.width;
              viewer.appendChild(canvas);

              page.render({ canvasContext: context, viewport });
            });
          }
        }
      });
    }, 1000);
    return () => clearTimeout(timer);
  }, [cdLaudo, getTokenLaudo]);
  return (
    <div className="laudo-view-container">
      {isAndroid() ? (
        <div>
          <div ref={viewerRef} className="pdf-viewer"></div>

          <Box sx={{ position: 'fixed', bottom: 16, right: 16 }}>
            <Button
              variant="contained"
              className="floating-button"
              onClick={download}
            >
              <CloudDownloadOutlinedIcon style={{ marginRight: '8px' }} />
            </Button>
          </Box>
        </div>
      ) : (
        <iframe
          src={getTokenLaudo()}
          title="Embedded Content"
          className="iframe"
        />
      )}
      {(isiOS() || isAndroid()) && (
        <Box sx={{ position: 'fixed', bottom: 16, right: 16 }}>
          <Button
            variant="contained"

            className="floating-button"
            onClick={download}
          >
            <CloudDownloadOutlinedIcon style={{ marginRight: '8px' }} />
          </Button>
        </Box>
      )}
    </div>
  );
}

export default LaudoView;
